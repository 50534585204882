import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { store } from '../redux/store'
import { config } from './config';
import { connect, setTokenBalance } from '../redux/web3Slice';
import { SteampadABI } from './SteampadABI.js'
import ICOContractABI from './contract/ICOContract.json';

let web3=new Web3(config.rpcUrl);
export const connectWallet = async () => {
    try {
        const state = store.getState();
        const address = state.web3Reducer.walletAddress;
        if (!address) {
            console.log('Connecting to wallet');
            // /*
            const providerOptions = {
                walletconnect: {
                    package: WalletConnectProvider,
                    options: {
                        bridge: "https://bridge.walletconnect.org",
                        // chainId: 137, //137 for mainnet
                        chainId: config.chainId, //137 for mainnet
                        // network: "matic", //matic for mainnet
                        network: "Binance Smart Chain", //matic for mainnet
                        rpc: {
                            97: config.rpcUrl
                            // 56: config.rpcUrl, //https://polygon-mainnet.g.alchemy.com/v2/QViRpMcPK99l3CnB-CjEOv1rFbzZXON9
                        },
                    }
                },
            };

            console.log("Provider is", providerOptions)

            const web3Modal = new Web3Modal({
                network: "matic",
                cacheProvider: false, // optional
                providerOptions, // required
                disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
            });

            console.log("Web3Modal instance is", web3Modal);
            const provider = await web3Modal.connect();



            provider.on("accountsChanged", async (accounts) => {
                console.log("Accounts", accounts)
                store.dispatch(connect(accounts[0]));
                const steamlandContract = new web3.eth.Contract(SteampadABI,config.contractAddress);
                const balance = await steamlandContract.methods.balanceOf(accounts[0]).call();
                const balanceInEth = web3.utils.fromWei(balance,"ether");
                store.dispatch(setTokenBalance({
                    balance:balance,
                    balanceInEth:balanceInEth,
                }))
            });

            web3 = new Web3(provider);
 

            console.log("Web3 instance is", web3);

            const chainid = await web3.eth.getChainId();

            console.log(chainid);
            if (chainid != config.chainId) {
                alert(`Please connect to ${config.networkName}`)
                return;
            }
            const accounts = await web3.eth.getAccounts();
            console.log("Acount is", accounts[0]);
            store.dispatch(connect(accounts[0]));
            const steamlandContract = new web3.eth.Contract(SteampadABI,config.contractAddress);
            const balance = await steamlandContract.methods.balanceOf(accounts[0]).call();
            const balanceInEth = web3.utils.fromWei(balance,"ether");
            store.dispatch(setTokenBalance({
                balance:balance,
                balanceInEth:balanceInEth,
            }))
        } else {
            console.log('Already connected')
        }
    } catch (err) {
        console.log(err);
    }
}


export const getICOContractInstance = async web3 => {
	const address = config.ICOContractAddress;
	return new Promise(resolve => {
		const contract = new web3.eth.Contract(ICOContractABI, address);
		resolve(contract);
	});
};

export const buyToken = async (value)=>{
    try {
        const state = store.getState();

        if(!state?.web3Reducer?.walletAddress){
            alert('Please connect your wallet');
            return;
        }

        const icoContract = await getICOContractInstance(web3);
        const valueInWei = web3.utils.toWei(value.toString(),"ether");
        await icoContract.methods.buyTokens(state?.web3Reducer?.walletAddress)
            .send({from: state?.web3Reducer?.walletAddress,
                value: valueInWei,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null,
            });

            alert('Successful');
    } catch (error) {
        console.log(error);
        alert(error.message)
    }
}

export const addSteampadToken = async ()=>{
    try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
  await window?.ethereum?.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20', // Initially only supports ERC20, but eventually more!
      options: {
        address: config.contractAddress, // The address that the token is at.
        symbol: "STPD", // A ticker symbol or shorthand, up to 5 chars.
        decimals: 18, // The number of decimals in the token
      },
    },
  });
    } catch (error) {
     console.log(error)
     alert(error)   
    }
}