import React from 'react';
import { Slide } from 'react-reveal';
import { Col, Container, Row } from 'reactstrap';
import token_commit from '../assets/token_commit.png';
import Table_1 from '../assets/Table_2.webp';

function TokenCommit() {
	return (
		<div className='ecosystem_section'>
			<Container className='pt-4 pb-4'>
				<Row className='justify-content-center mt-2'>
					<Col
						md='6'
						className='d-flex justify-content-center align-items-center'
					>
						<Slide left>
							<img
								src={token_commit}
								className='distribution_img'
							/>
						</Slide>
					</Col>
					<Col
						md='6'
						className='d-flex justify-content-center align-items-center'
					>
						<Slide right>
							<div>
								<p class='distribution_sub_heading'>
									A unique Token-commit extended vesting for
									investors.
								</p>
								<p className='distribution_sub_text'>
									Introducing a new system for users who hates
									long vesting periods, in this method users
									get to choose the vesting period and based
									on the selected vesting period we offer you
									the allocation.
								</p>
							</div>
						</Slide>
					</Col>
					<img src={Table_1} className='tier_small_img' />
				</Row>

			</Container>
		</div>
	);
}

export default TokenCommit;
