import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import { Fade, Slide, Zoom } from 'react-reveal';
import {
	VerticalTimeline,
	VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

function Roadmap() {
	return (
		<div className='roadmap_section  px-3'>
			<Container fluid>
				<Row>
					<Col className='mt-5'>
						<Zoom>
							<p className='background_text'>ROADMAP</p>
						</Zoom>
					</Col>
				</Row>
				<Row>
					<Col className='metaverse_sub_heading'>
						<Fade bottom>
							<p>
								<span className='sub_heading'>STEAMPAD</span>{' '}
								Roadmap
							</p>
						</Fade>
					</Col>
				</Row>

				{/* 				
				Left Timline */}
				<Row className='no-gutters mt-3 justify-content-start justify-content-md-end'>
					<Col xs='2'>
						{/* <div className='rectangle_box'></div> */}
						<i className='fa fa-circle main_circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
					</Col>
					<Col xs='9' md='5' className='p-0' align='start'>
						<Fade right>
							<div>
								<p className='roadmap_heading'>Phase 1</p>
								<p className='roadmap_p'>Web3 Development</p>
								<p className='roadmap_p'>
									Token Launch – Private and public sale
								</p>
								<p className='roadmap_p'>On-board strategic partnership </p>
								<p className='roadmap_p'>
									1st P2E Game development
								</p>
								<p className='roadmap_p'>Marketing</p>
							</div>
						</Fade>
					</Col>
				</Row>

				<Row className='no-gutters mt-5 justify-content-end justify-content-md-start'>
					<Col xs='9' md='5' className='p-0' align='end'>
						<Fade left>
							<div>
								<p className='roadmap_heading'>Phase 2</p>
								<p className='roadmap_p'>dApp development </p>
								<p className='roadmap_p'>Launchpad development</p>
								<p className='roadmap_p'> Game NFT release </p>
								<p className='roadmap_p'>
									Token Staking
								</p>

							</div>
						</Fade>
					</Col>
					<Col xs='2'>
						{/* <div className='rectangle_box'></div> */}
						<i className='fa fa-circle main_circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
					</Col>
				</Row>

				<Row className='no-gutters mt-5 justify-content-start justify-content-md-end'>
					<Col xs='2'>
						{/* <div className='rectangle_box'></div> */}
						<i className='fa fa-circle main_circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
						<i className='fa fa-circle'></i>
						<br />
					</Col>
					<Col xs='9' md='5' className='p-0' align='start'>
						<Fade right>
							<div>
								<p className='roadmap_heading'>Phase 3</p>
								<p className='roadmap_p'>
									Game release
								</p>
								<p className='roadmap_p'>
									Launchpad release
								</p>

								<p className='roadmap_p'>Guild Development </p>
								<p className='roadmap_p'>Scholarship management  </p>
								<p className='roadmap_p'>Dashboard </p>
							</div>
						</Fade>
					</Col>
				</Row>

				{/* <Row className='no-gutters justify-content-md-end mt-md-0 mt-4 roadmap_right_col'>
					<Col xs='2' className='d-flex align-items-center'>
						<div className='rectangle_box'></div>
					</Col>

					<Col
						xs='4'
						// align='left'
						className='d-flex align-items-center'
					>
						<Fade right>
							<h3 className='roadmap_heading'>Q1 2022</h3>
						</Fade>
					</Col>
				</Row>
				<Row className='no-gutters justify-content-md-end  roadmap_right_col'>
					<Col xs='2' className='d-flex align-items-center'>
						<i className='fa fa-circle'></i>
					</Col>
					<Col
						xs='4'
						// align='left'
						className='d-flex align-items-center'
					>
						<Fade right>
							<p className='roadmap_p'>Web3 Development</p>
						</Fade>
					</Col>
				</Row>
				<Row className='no-gutters justify-content-md-end roadmap_right_col'>
					<Col xs='2' className='d-flex align-items-center'>
						<i className='fa fa-circle'></i>
					</Col>
					<Col
						xs='4'
						// align='left'
						className='d-flex align-items-center'
					>
						<p className='roadmap_p'>
							Token Launch – Private and public sale
						</p>
					</Col>
				</Row>
				<Row className='no-gutters justify-content-md-end  roadmap_right_col'>
					<Col xs='2' className='d-flex align-items-center'>
						<i className='fa fa-circle'></i>
					</Col>
					<Col
						xs='4'
						// align='left'
						className='d-flex align-items-center'
					>
						<p className='roadmap_p'>DEX Listing</p>
					</Col>
				</Row>
				<Row className='no-gutters justify-content-md-end roadmap_right_col'>
					<Col xs='2' className='d-flex align-items-center'>
						<i className='fa fa-circle'></i>
					</Col>
					<Col
						xs='4'
						// align='left'
						className='d-flex align-items-center'
					>
						<p className='roadmap_p'>
							On-board strategic partnership
						</p>
					</Col>
				</Row>
				<Row className='no-gutters justify-content-md-end roadmap_right_col'>
					<Col xs='2' className='d-flex align-items-center'>
						<i className='fa fa-circle'></i>
					</Col>
					<Col xs='4' className='d-flex align-items-center'>
						<p className='roadmap_p'>Launchpad live</p>
					</Col>
				</Row> */}

				{/* <Row className='d-flex justify-content-end no-gutters'>
					<Col
						md='6'
						className='d-flex justify-content-start align-items-center'
					>
						<div className='rectangle_box me-5'></div>
						<h3>Q1 2022</h3>
					</Col>
				</Row>
				<Row className='d-flex justify-content-end mt-4'>
					<Col
						md='6'
						className='d-flex justify-content-start align-items-center'
					>
						<i className='fa fa-circle '></i>
						<p>Web3 Development</p>
					</Col>
				</Row>
				<Row className='d-flex justify-content-end '>
					<Col
						md='6'
						className='d-flex justify-content-start align-items-center'
					>
						<i className='fa fa-circle '></i>
						<p>Token Launch – Private and public sale</p>
					</Col>
				</Row>
				<Row className='d-flex justify-content-end '>
					<Col
						md='6'
						className='d-flex justify-content-start align-items-center'
					>
						<i className='fa fa-circle '></i>
						<p>DEX Listing</p>
					</Col>
				</Row>
				<Row className='d-flex justify-content-end '>
					<Col
						md='6'
						className='d-flex justify-content-start align-items-center'
					>
						<i className='fa fa-circle '></i>
						<p>On-board strategic partnership</p>
					</Col>
				</Row>
				<Row className='d-flex justify-content-end '>
					<Col
						md='6'
						className='d-flex justify-content-start align-items-center'
					>
						<i className='fa fa-circle '></i>
						<p>Launchpad live</p>
					</Col>
				</Row> */}

				{/* Second timeline */}

				{/* <Row className='d-flex  justify-content-end no-gutters'>
					<Col
						className='d-flex justify-content-end align-items-center'
						md='6'
					>
						<h3 className='me-3'>Q1 2022</h3>
					</Col>
					<Col
						md='6'
						className='d-flex justify-content-start align-items-center'
					>
						<div className='rectangle_box '></div>
					</Col>
				</Row>
				<Row className='d-flex justify-content-end'>
					<Col
						className='d-flex justify-content-end align-items-center'
						sm='6'
						md='6'
					>
						<p className='me-3'>DAO Maker</p>
					</Col>
					<Col
						sm='6'
						md='6'
						className='d-flex justify-content-start align-items-center'
					>
						<i className='fa fa-circle '></i>
					</Col>
				</Row> */}
			</Container>
		</div>
	);
}

export default Roadmap;
