import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import play1 from '../assets/Play1.png';
import play2 from '../assets/Play2.png';
import play3 from '../assets/Play3.png';


function PlayToEarn() {
	return (
		<div className='playtoearn px-3'>
			<Container>
				<Row>
					<Col className='yodha_heading'>
						<p>
							Play To Earn
						</p>
						<div class="divider"></div>
					</Col>
				</Row>

				<Row className='no-gutters p-md-5 d-none d-md-flex'>
					<Col md='6' className='pb-4'>

						<div className='play_col1 p-3 d-flex justify-content-center flex-column align-items-left'>
							<h5 className='mt-0'>Earn while playing </h5>
							<p px-2>
								Earn crypto in $STPD tokens as in-game rewards through playing and
								completing PVE levels, performing special achievements, and
								win prizes in tournaments and events, also be the lucky ones to
								find the rare NFT drops in-game while completing higher levels.

							</p>
						</div>
					</Col>
					<Col md='6' className='pb-4'>

						<div >
							<img src={play1} className='play__left_img'></img>
						</div>

					</Col>

					<Col md='6' className='pb-4'>

						<div >
							<img src={play2} className='play__left_img'></img>
						</div>

					</Col>
					<Col md='6' className='pb-4'>
						<div className='play_col2 p-3 d-flex justify-content-center flex-column align-items-right'>
							<h5 className='mt-0'>Level them up</h5>
							<p px-2>
								Strategize and Build your perfect Hero’s team and level them up to
								defeat the powerful villains in the Game. Use the earned Yodha
								tokens and the special NFT drop rewards to increase your Hero levels
								and make them the most powerful in the Game to face our
								Mightiest Villains.
							</p>
						</div>
					</Col>
					<Col md='6' className='p-0'>
						<div className='play_col1 p-3 d-flex justify-content-center flex-column align-items-left'>
							<h5 className='mt-0'>Defeat them </h5>
							<p px-2>
								Collect the right set of heroes and strategize the right skillset to
								defeat our ferocious villains and move up the ladder to higher levels
								to earn more rewards and tokens for every level, the higher the levels
								the more earnings to your bag. Defeat all our mighty villains and make
								these NFTs yours to earn additional passive income, additionally, these
								NFTs will play a major role when PvP mode is released in the future.
							</p>
						</div>
					</Col>
					<Col md='6' className='p-0'>
						<div >
							<img src={play3} className='play__left_img'></img>
						</div>
					</Col>
				</Row>

				<Row className='no-gutters d-block d-md-none mt-4'>
					<Col md='6' className='p-0'>
						<div>
							<img src={play1} className='play__left_img'></img>
						</div>
					</Col>
					<Col md='6' className='p-0'>
						<div className='play_col p-2 d-flex justify-content-center flex-column align-items-center'>
							<h5 className='mt-0'>Earn while playing </h5>
							<p px-2>
								Earn crypto in $STPD tokens as in-game rewards through playing and
								completing PVE levels, performing special achievements, and
								win prizes in tournaments and events, also be the lucky ones to
								find the rare NFT drops in-game while completing higher levels.
							</p>
						</div>
					</Col>

					<Col md='6' className='p-0'>
						<div>
							<img src={play2} className='play__left_img'></img>
						</div>
					</Col>

					<Col md='6' className='p-0'>
						<div className='play_col p-2  d-flex justify-content-center flex-column align-items-center'>
							<h5 className='mt-0'>Level them up</h5>
							<p px-2>
								Strategize and Build your perfect Hero’s team and level them up to
								defeat the powerful villains in the Game. Use the earned Yodha
								tokens and the special NFT drop rewards to increase your Hero levels
								and make them the most powerful in the Game to face our
								Mightiest Villains.
							</p>
						</div>
					</Col>
					<Col md='6' className='p-0'>
						<div>
							<img src={play3} className='play__left_img'></img>
						</div>
					</Col>
					<Col md='6' className='p-0'>
						<div className='play_col p-2 d-flex justify-content-center flex-column align-items-center'>
							<h5 className='mt-0'>Defeat Them </h5>
							<p px-2>
								Collect the right set of heroes and strategize the right skillset to
								defeat our ferocious villains and move up the ladder to higher levels
								to earn more rewards and tokens for every level, the higher the levels
								the more earnings to your bag. Defeat all our mighty villains and make
								these NFTs yours to earn additional passive income, additionally, these
								NFTs will play a major role when PvP mode is released in the future.
							</p>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default PlayToEarn;
