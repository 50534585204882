import React from 'react';
import { Container } from 'reactstrap';

function Trading() {
	return (
		<div className='d-flex trading_main trading_section'>
			<div className='d-flex flex-column align-items-start trading_col'>
				<span className='trade_title'>TRADING VOLUME 24H</span>
				<div className='d-flex flex-row'>
					<span className='trade_growth_title'>ILA/USDT</span>
					<span className='trade_growth_fail'>-0.87%</span>
				</div>
				<span class='trade_report_value'>28,511,601.59</span>
				<span class='trade_growth_sub'>$1,876,063.38</span>
			</div>
			<div className='d-flex flex-column align-items-start trading_col'>
				<span className='trade_title'>TRADING VOLUME 24H</span>
				<div className='d-flex flex-row'>
					<span className='trade_growth_title'>ILA/USDT</span>
					<span className='trade_growth_fail'>-0.87%</span>
				</div>
				<span class='trade_report_value'>28,511,601.59</span>
				<span class='trade_growth_sub'>$1,876,063.38</span>
			</div>
			<div className='d-flex flex-column align-items-start trading_col'>
				<span className='trade_title'>TRADING VOLUME 24H</span>
				<div className='d-flex flex-row'>
					<span className='trade_growth_title'>ILA/USDT</span>
					<span className='trade_growth_fail'>-0.87%</span>
				</div>
				<span class='trade_report_value'>28,511,601.59</span>
				<span class='trade_growth_sub'>$1,876,063.38</span>
			</div>
			<div className='d-flex flex-column align-items-start trading_col'>
				<span className='trade_title'>
					TOTAL VALUE LOCKED IN STAKING POOL
				</span>
				<div className='d-flex flex-row'>
					<span className='trade_growth_title'>ILA/USDT</span>
					<span className='trade_growth_fail'>-0.87%</span>
				</div>
				<span class='trade_report_value'>28,511,601.59</span>
				<span class='trade_growth_sub'>$1,876,063.38</span>
			</div>
		</div>
	);
}

export default Trading;
