import React, { useState, useEffect } from 'react';
import logo1 from '../assets/logo1.png';
import {
	Navbar,
	NavbarBrand,
	Collapse,
	Nav,
	NavItem,
	NavLink,
	Button,
	Modal,
	ModalBody,
	Input,
	Form,
	DropdownItem,
	NavbarText,
	UncontrolledDropdown,
	DropdownToggle,
	NavbarToggler,
	DropdownMenu,
	Tooltip,
} from 'reactstrap';
import AddressBar from './AddressBar';
import { connectWallet } from '../utils/web3';
import { useSelector } from 'react-redux';

function Header({ type, executeScroll, showNav }) {
	let [collapsed, setCollapsed] = useState(false);
	const state = useSelector(state => state);

	const toggle = () => {
		setCollapsed(!collapsed);
	};
	console.log('Show nav', showNav);

	const connect = () => {
		connectWallet();
	};

	let [tooltipOpen, setTooltipOpen] = useState(false);
	let [tooltipOpen2, setTooltipOpen2] = useState(false);
	let [tooltipOpen3, setTooltipOpen3] = useState(false);
	let [tooltipOpen4, setTooltipOpen4] = useState(false);

	const toggle1 = () => {
		setTooltipOpen(!tooltipOpen);
	};
	const toggle2 = () => {
		setTooltipOpen2(!tooltipOpen2);
	}
	const toggle3 = () => {
		setTooltipOpen3(!tooltipOpen3);
	};
	const toggle4 = () => {
		setTooltipOpen4(!tooltipOpen4);
	};



	return (
		<>
			<div className='main_header fixed-nav-bar'>
				{showNav && <AddressBar />}

				<Navbar expand='lg' className='px-0 px-lg-2 main_navbar'>
					<NavbarToggler onClick={toggle} className='mx-auto'>
						{collapsed ? (
							<i
								aria-hidden='true'
								tabindex='0'
								className='far fa-window-close'
							></i>
						) : (
							<i class='fa fa-list'></i>
						)}
					</NavbarToggler>
					<Collapse
						navbar
						isOpen={collapsed}
						// className={`${collapsed && 'toggle_background'}`}
						className='nav_collapse '
					>
						<Nav
							className='d-flex  align-items-start align-items-lg-center'
							navbar
						>
							<NavItem>
								<NavLink href='/launchpad'>LAUNCHPAD</NavLink>
							</NavItem>
						</Nav>
						<Nav
							className='d-flex align-items-start align-items-lg-center'
							navbar
						>
							<NavItem>
								<NavLink href="/" id='TooltipExample'>GUILD</NavLink>
								<Tooltip
									placement='bottom'
									isOpen={tooltipOpen}
									target='TooltipExample'
									toggle={toggle1}
								>
									Coming Soon
								</Tooltip>
							</NavItem>

						</Nav>
						<Nav
							className='d-flex align-items-start align-items-lg-center'
							navbar
						>
							<NavItem>
								<NavLink href='/yodha' id='TooltipExample2'>YODHA</NavLink>
								{/*<Tooltip
									placement='bottom'
									isOpen={tooltipOpen2}
									target='TooltipExample2'
									toggle={toggle2}
								>
									Coming Soon
						</Tooltip>*/}
							</NavItem>

						</Nav>
						<Nav
							className='d-flex align-items-start align-items-lg-center'
							navbar
						>
							<NavItem>
								<NavLink href='/'>
									<img src={logo1} className='navbar__logo' />
								</NavLink>
							</NavItem>
						</Nav>

						<Nav
							className='d-flex align-items-start align-items-lg-center'
							navbar
						>
							<NavItem>
								<NavLink href='SteamPad-Doc.pdf' target="_blank" id='TooltipExample3'>Documentation</NavLink>
							</NavItem>
						</Nav>
						<Nav
							className='d-flex align-items-start align-items-lg-center'
							navbar
						>
							<NavItem>
								<NavLink href='/' id="TooltipExample4">MARKETPLACE</NavLink>
							</NavItem>
							<Tooltip
								placement='bottom'
								isOpen={tooltipOpen4}
								target='TooltipExample4'
								toggle={toggle4}
							>
								Coming Soon
							</Tooltip>
						</Nav>

						<Nav
							className='d-flex  align-items-start align-items-lg-center'
							navbar
						>
							<NavItem className=''>
								<Button className='launch_btn' onClick={connect}>
									{state.web3Reducer.shortAddress
										? state.web3Reducer.shortAddress
										: 'CONNECT WALLET'}
								</Button>
							</NavItem>
						</Nav>

						<Nav
							className='d-flex  align-items-start align-items-lg-center'
							navbar
						>
							<NavItem className=''>
								{state.web3Reducer.shortAddress &&
									<NavLink href='/'>{state.web3Reducer.balance} STPD</NavLink>
								}
							</NavItem>
						</Nav>

						{/* <Nav
						className='d-flex justify-content-center align-items-center'
						navbar
					>
						<NavItem>
							<NavLink href='/'>LAUNCHPAD</NavLink>
						</NavItem>

						<NavItem>
							<NavLink href='/'>GUILD</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href='/'>METAVERSE</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href='https://github.com/reactstrap/reactstrap'>
								<img src={logo1} className='navbar__logo' />
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href='/'>Documentation</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href='/'>STAKING</NavLink>
						</NavItem>
						<NavItem className='d-block d-lg-none'>
							<NavLink href='/' className='d-none d-md-block'>
								LAUNCH APP
							</NavLink>
						</NavItem>
						<NavItem className='d-none d-lg-block'>
							<NavLink href='/' className='d-none d-md-block'>
								<Button className='launch_btn'>
									LAUNCH APP
								</Button>
							</NavLink>
						</NavItem>
					</Nav> */}
					</Collapse>
				</Navbar>
			</div>
		</>
	);
}

export default Header;
