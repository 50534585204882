import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import Banner from '../components/Banner';
import Distribution from '../components/Distribution';
import Footer from '../components/Footer';
import Header from '../components/Header';

import SecondHeader from '../components/SecondHeader';
import Trading from '../components/Trading';
import Tier from '../components/Tier';
import Manifesto from '../components/Manifesto';
import TokenCommit from '../components/TokenCommit';
import IDO from '../components/IDO';
import Table_1 from '../assets/Table_2.webp';

function Launchpad() {
	const [showNav, setShowNav] = useState(true);

	const hideNav = () => {
		if (window.scrollY >= 80) {
			setShowNav(false);
		} else {
			setShowNav(true);
		}
	};

	window.addEventListener('scroll', hideNav);
	return (
		<div>
			<Header />
			<Banner />
			<Distribution />
			<TokenCommit />
			<IDO />
			<Tier />
			<Manifesto />
			<Footer />
		</div>
	);
}

export default Launchpad;
