import React from 'react';
import { Slide } from 'react-reveal';
import { Col, Container, Row } from 'reactstrap';
import token_commit from '../assets/token_commit.png';
import ido from '../assets/ido.png';

function IDO() {
	return (
		<div className='ecosystem_section'>
			<Container className='pt-4 pb-4'>
				<Row className='justify-content-center mt-2'>

					<Col
						md='6'
						className='d-flex justify-content-center align-items-center'
					>
						<Slide right>
							<div>
								<p class='distribution_sub_heading'>
									IDO For All
								</p>
								<p className='distribution_sub_text'>
									Want to participate in your favourite projects IDO
									without holding our Launchpad Tokens. We heard you and
									would like to offer users, an opportunity to participate
									in their favourite projects IDO by not holding our Tokens,
									Users can get guaranteed allocations by committing to vest
									the tokens longer than the vesting schedule of projects.
									Users will be selected based on lottery system. By this way,
									project owners can have a considerable number of long-term
									holders to support their project.
								</p>
							</div>
						</Slide>
					</Col>
					<Col
						md='6'
						className='d-flex justify-content-center align-items-center'
					>
						<Slide left>
							<img
								src={token_commit}
								className='distribution_img'
							/>
						</Slide>
					</Col>
				</Row>

			</Container>
		</div>
	);
}

export default IDO;
