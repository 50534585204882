import React, { useState } from 'react';
import { Fade, Zoom } from 'react-reveal';
import { Col, Container, Row } from 'reactstrap';
import MainPageChart from '../assets/Main-Page-Chart.png';
import video1 from '../assets/WebIntro.mp4';
import ReactPlayer from "react-player";
import { NavLink, Button } from 'reactstrap';
import { addSteampadToken, buyToken } from '../utils/web3';

function Metaverse() {

	const [value, setValue] = useState();

	const buyTokenHandler = async () => {
		try {
			await buyToken(value);
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<div className='metaverse_section p-3'>
			<Container fluid>
				<Row>
					<Col className='mt-2'>
						<Zoom>
							<p className='background_text'>METAVERSE</p>
						</Zoom>
					</Col>
				</Row>
				<Row>
					<Col className='metaverse_sub_heading'>
						<Fade bottom>
							<p>
								<span className='sub_heading'>STEAMPAD</span>{' '}
								{/* <br className='d-block d-md-none' /> a True */}
								Metaverse Platform
							</p>
						</Fade>
					</Col>
				</Row>
				<Row className='justify-content-center mt-4 md-2 mx-5'>
					<Fade left>

						<p className='metaverse_p'>
							We’re bringing the experience of Blockchain
							Gaming, NFTs & Metaverses to millions of people.
							And we invite you to be part of our vision.
							<br />
							Steampad focus is to bring the complete
							Metaverse ecosystem at one place by introducing
							the $STPD Token which will be the native token
							and the central part of our ecosystem, SteamPad
							Token will be used to access a wide range of
							products starting with our Launchpad, Gaming
							Guild and P2E Games.
						</p>
					</Fade>
				</Row>
				<Row className='justify-content-center metaverse_row mt-4 mt-md-0'>
					{/*<Col className='mt-5'>
						<div className="player-wrapper">
							<ReactPlayer
								url={video1}
								className="react-player"
								playing
								width="100%"
								height="100%"
								controls={true}
							/>
						</div>
					</Col>*/}
					<Col className='mt-5'>

						<div className='form'>
							<p className='metaverse_s'>
								Private sale round 1 at $0.001 per $STPD Token
							</p>
							<p className='metaverse_s1'>
								Enter the amount in BNB. The minimum buy is 0.1 BNB and the Maximum is 6 BNB.
							</p>
							<div className='p_field_top>'>
								<div className='p_field'>
									<div className='field_text_top'>
										<p class="field_text">Amount</p>
									</div>

									<div className='field_top1'>
										<div className='field_top'>
											<input type="number" aria-invalid="false" name="numberformat" placeholder="Enter Amount"
												inputmode="numeric" id="mui-2" className='field' min={0} max={6} value={value} onChange={(e) => setValue(parseFloat(e.target.value))} />
										</div>
									</div>
								</div>
							</div>
							<p className='metaverse_s1'>
								Avg Price: 400,000 $STPD Tokens per 1 BNB.
							</p>
							<p className='metaverse_s1'>
								*Launch price will be around $0.01 - $0.04 on Pancake.
							</p>
							<div className='button_top'>
								<button class="button" tabindex="-1" type="button" disabled="" id="mui-3" onClick={buyTokenHandler}>Buy </button>
							</div>
							<p className='metaverse_s2' onClick={() => addSteampadToken()}>
								Add STEAMPAD to Metamask
							</p>
						</div>

					</Col>
					<Col md='6' className='d-flex justify-content-center align-items-center'>
						<img src={MainPageChart} className='chart_img' />
					</Col>
				</Row>
				{/*<Row className='mt-5 md-2 pt-5'>
					<Fade left>
						<p className='metaverse_p'>
							Pre-Sale begins on the 12th of March.
						</p>
					</Fade>
				</Row>
				<Row className='mt-2 md-2'>
					<form action='https://www.pinksale.finance/#/launchpad/0x2D82e00acaBEe26aF6C31e00a6De25Ced0425Ab8?chain=BSC'>
						<button type='submit' className='sale_btn'>
							Pre Sale
						</button>
					</form>
				</Row>*/}
			</Container >
		</div >
	);
}

export default Metaverse;
