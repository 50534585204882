import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Logo_Y from '../assets/Yodha_Logo.png'
import All from '../assets/All.png'

function Yodha_Section1() {
    return (
        <div className='yodha_section1 '>
            <Container className=''>
                <Row>
                    <Col>
                        <img src={Logo_Y} className='yodha_logo' />
                        <div class="divider"></div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <img src={All} className='yodha_all' />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Yodha is a cartoon-inspired, play-to-earn game on a blockchain network
                            based on the epic war fought in the ancient history of Bharat.
                            Dive into supernatural powers and immerse yourself in brand new,
                            original storylines starring your favorite characters from the history
                            while you explore a new Game experience!</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Yodha_Section1;
