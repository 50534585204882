import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import logo_black from '../assets/logo_black.png';
import logo_white from '../assets/logo_white.png';
import Cert from '../assets/Cert.jpg';

function Footer() {
	return (
		<footer>
			<Container fluid>
				<Row className='footer_row  no-gutters p-md-4 d-none d-md-flex'>
					<Col>
						{' '}
						<p> Powered by $STPD</p>
						<img src={logo_white} className='footer_img' alt='' />
					</Col>
					<Col >
						{' '}

						<a href="https://github.com/coinscope-co/audits/blob/main/stpd/audit.pdf" target="_blank">
							<img src={Cert} className='footer_img pt-4 ' alt='' />
						</a>
					</Col>

					<Col>
						<h2>About</h2>
						<br />
						<a href='#'>Overview</a>
						<br />
						<a href='#'>Ecosystem</a>
						<br />
						<a href='#'>Roadmap</a>
						<br />
						<a href='#'>Tokenomics</a>
						<br />
						<a href='#'>Team</a>
					</Col>
					<Col className='d-flex flex-column pt-4'>
						<div className='d-flex  justify-content-between align-items-center'>
							<div className='footer_icon me-3'>
								<i class='fab fa-twitter'></i>
							</div>
							<a
								href='https://twitter.com/Steampad'
								target='_blank'
							>
								Follow us on Twitter
							</a>

							<div className='footer_icon me-3'>
								<i class='fab fa-telegram'></i>
							</div>
							<a
								href='https://t.me/SteamPad_official_group'
								target='_blank'
							>
								Join us on Telegram
							</a>
						</div>
						<br />

						<div className='d-flex  justify-content-between align-items-center'>
							<div className='footer_icon me-3'>
								<i class='fab fa-facebook'></i>
							</div>
							<a
								href=' https://www.facebook.com/SteamPad-101447209232548/'
								target='_blank'
							>
								Follow us on Facebook
							</a>
							<div className='footer_icon me-3'>
								<i class='fab fa-instagram'></i>
							</div>
							<a
								href='https://www.instagram.com/steampad_nft/'
								target='_blank'
							>
								Follow us on instagram
							</a>
						</div>
					</Col>
				</Row>

				<Row className='footer_row  no-gutters d-block d-md-none mt-4'>
					<Col>
						{' '}
						<p> Powered by $STPD</p>
						<img src={logo_white} className='footer_img' alt='' />
					</Col>
					<Col >
						{' '}

						<a href="https://github.com/coinscope-co/audits/blob/main/stpd/audit.pdf" target="_blank">
							<img src={Cert} className='footer_img pt-4 ' alt='' />
						</a>
					</Col>

					<Col>
						<h2>About</h2>
						<br />
						<a href='#'>Overview</a>
						<br />
						<a href='#'>Ecosystem</a>
						<br />
						<a href='#'>Roadmap</a>
						<br />
						<a href='#'>Tokenomics</a>
						<br />
						<a href='#'>Team</a>
					</Col>
					<Col className='d-flex flex-column pt-4'>
						<div className='d-flex  justify-content-between align-items-center'>
							<div className='footer_icon me-3'>
								<i class='fab fa-twitter'></i>
							</div>
							<a
								href='https://twitter.com/Steampad'
								target='_blank'
							>
								Follow us on Twitter
							</a>

							<div className='footer_icon me-3'>
								<i class='fab fa-telegram'></i>
							</div>
							<a
								href='https://t.me/SteamPad_official_group'
								target='_blank'
							>
								Join us on Telegram
							</a>
						</div>
						<br />

						<div className='d-flex  justify-content-between align-items-center'>
							<div className='footer_icon me-3'>
								<i class='fab fa-facebook'></i>
							</div>
							<a
								href=' https://www.facebook.com/SteamPad-101447209232548/'
								target='_blank'
							>
								Follow us on Facebook
							</a>
							<div className='footer_icon me-3'>
								<i class='fab fa-instagram'></i>
							</div>
							<a
								href='https://www.instagram.com/steampad_nft/'
								target='_blank'
							>
								Follow us on instagram
							</a>
						</div>
					</Col>
				</Row>

				<Row className='copy_row justify-content-center align-items-center'>
					<Col className='py-2 d-flex justify-content-center align-items-center'>
						<p className='copy_p'>
							All Right Reserved By Steampad.org
						</p>
					</Col>
				</Row>
			</Container>
		</footer >
	);
}

export default Footer;
