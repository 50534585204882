import logo from './logo.svg';
import './App.css';
import Home from './views/Home';
import { BrowserRouter, Routes, Route, Link, Outlet } from 'react-router-dom';
import Launchpad from './views/Launchpad';
import Yodha from './views/Yodha';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import Documentation from './views/Documentation';

function App() {
	return (
		<Provider store={store}>
			<div className='App'>
				<BrowserRouter>
					<Routes>
						<Route exact path='/' element={<Home />} />
						<Route
							exact
							path='/launchpad'
							element={<Launchpad />}
						/>
						<Route
							exact
							path='/yodha'
							element={<Yodha />}
						/>
						{/*<Route
							exact
							path='/documentation'
							element={<Documentation />}
	/>*/}
					</Routes>
				</BrowserRouter>
			</div>
		</Provider>
	);
}

export default App;
