import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import Arjun from '../assets/Arjun.png';
import Ram from '../assets/Ram.png';
import Karna from '../assets/Karna.png';
import Bhishma from '../assets/Bhishma.png';
import Ravan from '../assets/Ravan.png';
import Duryodhan from '../assets/Duryodhan.png';



function CollectThemAll() {

    return (

        <div className="yodha_collect">
            <div>
                <h2 className='yodha_heading'>Collect Them All</h2>
                <div class="divider"></div>
            </div>
            <Swiper initialSlide={2}
                navigation={true}
                effect={"coverflow"}
                centeredSlides={true}
                slidesPerView={5}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false
                }}
                pagination={{
                    clickable: true
                }}
                spaceBetween={-250}
                className="mySwiper"

            >
                <SwiperSlide>
                    <img src={Bhishma} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={Karna} />
                </SwiperSlide>
                <SwiperSlide >
                    <img src={Ram} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={Arjun} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={Duryodhan} />
                </SwiperSlide>
                {/*<SwiperSlide>
                    <img src={Ravan} />
                </SwiperSlide>*/}
            </Swiper>
            <div>
                <p>
                    In this game, you collect Hero characters from Ancient times, level them up, and
                    give them gear to fight off Villains from the same Ancient times. Once you defeat a
                    Villain character you own them to your NFT collections and earn passive Income
                    through these NFTs. Build the perfect Hero team and upgrade their powers &
                    skillset to defeat all the Villains on your path towards the Final Throne.
                </p>
            </div>
        </div>


    );

}

export default CollectThemAll;
