import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Fade, Zoom } from 'react-reveal';

function Manifesto() {
	return (
		<div className='manifesto_section p-3'>
			<Container fluid>
				<Row>
					<Col className='mt-3'>
						<Zoom>
							<p className='background_text'>MANIFESTO</p>
						</Zoom>
					</Col>
				</Row>
				<Row>
					<Col className='metaverse_sub_heading'>
						<Fade bottom>
							<p>
								<span className='sub_heading'>Manifesto</span>{' '}
								{/* <br className='d-block d-md-none' /> a True */}
								Manifesto
							</p>
						</Fade>
					</Col>
				</Row>

				<Row className='manifesto_row p-2 p-md-5 mt-4 mt-md-0'>
					<Col md='6'>
						<p className='manifesto_body_p'>
							We are now creating a brand-new reality of an
							unconstrained Multiverse. As members, as Citizens of
							this Multiverse, we will together bring imagination
							to life. We will together, create a world that’s
							unconstrained — our highest expression of creative
							freedom. SteamPad is driven by humanity’s mission
							into the ‘worlds beyond worlds’… a new reality for
							everyone, not just a few. Join us, let us build this
							Multiverse together………
						</p>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default Manifesto;
