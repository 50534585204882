import { Zoom } from '@mui/material';
import React from 'react';
import { Slide } from 'react-reveal';
import { Container, Row, Col, Fade } from 'reactstrap';
import distri from '../assets/distri.png';
import fair_dist from '../assets/fair_dist.png';
function Distribution() {
	return (
		<div className='distribution_section'>
			<Container className='pt-5 pb-3'>
				<Row>
					<Col>
						<h2 className='distribution_heading'>Why Steampad?</h2>
					</Col>
				</Row>

				<Row className='justify-content-center'>
					<Col
						md='6'
						className='d-flex flex-column justify-content-center align-items-center'
					>
						<Slide left>
							<div>
								<p class='distribution_sub_heading'>
									Fair and Transparent
								</p>
								<p className='distribution_sub_text'>
									SteamPad ensures fairness and transparency
									for everyone through its decentralized
									nature and inbuilt price discovery mechanism
									and help projects effectively raise targeted
									funds
								</p>
							</div>
						</Slide>
					</Col>

					<Col
						md='6'
						className='d-flex justify-content-center align-items-center'
					>
						<Slide right>
							<img src={fair_dist} className='distribution_img' />
						</Slide>
					</Col>
				</Row>

				{/* <Row className='justify-content-center mt-2'>
					<Col
						md='6'
						className='d-flex justify-content-center align-items-center'
					>
						<Slide left>
							<div>
								<p class='distribution_sub_heading'>
									A unique Token-commit extended vesting for
									investors.
								</p>
							</div>
						</Slide>
					</Col>
					<Col
						md='6'
						className='d-flex flex-column justify-content-center align-items-center'
					>
						<Slide right>
							<div>
								<p className='distribution_sub_text'>
									Introducing a new system for users who hates
									long vesting periods, in this method users
									get to choose the vesting period and based
									on the selected vesting period we offer you
									the allocation.
								</p>
							</div>
						</Slide>
					</Col>
				</Row>

				<Row className='mt-5'>
					<Col className='d-flex jutify-content-center align-items-center'>
						<div>
							<p className='distribution_sub_text1'>
								Want to participate in your favourite projects
								IDO without holding our Launchpad Tokens. We
								heard you and would like to offer users, an
								opportunity to participate in their favourite
								projects IDO by not holding our Tokens, Users
								can get guaranteed allocations by committing to
								vest the tokens longer than the vesting schedule
								of projects. Users will be selected based on
								lottery system. By this way, project owners can
								have a considerable number of long-term holders
								to support their project.
							</p>
						</div>
					</Col>
				</Row> */}
			</Container>
		</div>
	);
}

export default Distribution;
