import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import logo_white from '../assets/logo_white.png';

function Banner() {
	return (
		<div className='launchpad_banner '>
			<Container className=''>
				<Row>
					<Col
						md='6'
						className='d-flex flex-column justify-content-center align-items-center align-items-md-start'
					>
						<p className='banner_heading'>STEAMPAD</p>
						<span className='banner_sub_heading'>
							A fund raising platform exclusive for Blockchain
							Gaming looks to completely enhance the way new
							projects gather liquidity and how participating
							users can gain most from it to get the best yield.
						</span>
					</Col>
					<Col
						md='6'
						className='d-flex flex-column jusitfy-content-center align-items-center align-items-md-start'
					>
						<img src={logo_white} className='launch_img' />
					</Col>
				</Row>
			</Container>
			;
		</div>
	);
}

export default Banner;
