import React, { useState, useEffect } from 'react';
import ReactPlayer from "react-player";
import headroom from 'react-headroom';
import SwiperCore, { EffectCoverflow, Pagination, Navigation } from "swiper/core";
import Footer2 from '../components/Footer2';
import ThirdHeader from '../components/ThirdHeader';
import YodhaSection1 from '../components/YodhaSection1';
import CollectThemAll from '../components/CollectThemAll';
import PlayToEarm from '../components/PlayToEarn';
import video1 from '../assets/Video1.mp4';



SwiperCore.use([EffectCoverflow, Pagination, Navigation]);

function Yodha() {
	const [showNav, setShowNav] = useState(true);

	const hideNav = () => {
		if (window.scrollY >= 80) {
			setShowNav(false);
		} else {
			setShowNav(true);
		}
	};

	window.addEventListener('scroll', hideNav);
	return (
		<div>
			<ThirdHeader showNav={showNav} />

			{/*<video loop autoPlay muted className='video1'>
				<source
					src={video1}
					type="video/mp4"
				/>
	</video>*/}


			<div className='bodyyodha'>
				<div className='video-player'>
					<ReactPlayer
						url={video1}
						muted={true}
						loop={true}
						autoplay={true}
						playing
						width="100%"
						height="100%"
						controls={false}
						className='video1'
					/>
				</div>
				<YodhaSection1 />
				<div className='art_work1' >
				</div>
				<CollectThemAll />
				<div className='art_work2' >
				</div>
				<PlayToEarm />
			</div>
			<Footer2 />
		</div >
	);
}

export default Yodha;



