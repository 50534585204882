import React from 'react';
import {
	Col,
	Container,
	Row,
	Table,
	Card,
	CardBody,
	CardHeader,
} from 'reactstrap';
import tier_ranking from '../assets/tier_ranking.png';
import token_tier from '../assets/token_tier.png';
import tier_token_table2 from '../assets/tier_token_table2.png';

function Tier() {
	return (
		<div className='ecosystem_section'>
			<Container className=' pt-5 pb-5'>
				<Row>
					<Col>
						<p className='tier_heading'>
							Tier Ranking System for token holders
						</p>
					</Col>
				</Row>
				<Row>
					<Col>
						<img
							src={tier_token_table2}
							alt=''
							className='tier_img'
						/>
					</Col>
				</Row>
				{/* <Row>
					<Col>
						<Table
							className='align-items-center table-dark table-flush'
							responsive
						>
							<thead className='thead-dark'>
								<tr>
									<th scope='col' className='main_th'></th>
									<th scope='col'>
										Silver <br /> 10,000 STPD
									</th>
									<th scope='col'>
										Gold <br />
										40,000 STPD
									</th>
									<th scope='col'>
										Platinum <br /> 100,000 STPD
									</th>
									<th scope='col'>
										Diamond <br /> 250,000 STPD
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className='main_td'>
										Guaranteed allocation
									</td>
									<td className='light_background'>
										No(Lottery)
									</td>
									<td className='light_background'>Yes</td>
									<td className='light_background'>Yes</td>
									<td className='light_background'>Yes</td>
								</tr>
								<tr>
									<td className='main_td'>
										Maximum Allocation per Individual
									</td>
									<td className='dark_background'>$20</td>
									<td className='dark_background'>$90</td>
									<td className='dark_background'>$250</td>
									<td className='dark_background'>$1000</td>
								</tr>
								<tr>
									<td className='main_td'>
										Private Sale Invite
									</td>
									<td className='light_background'>No</td>
									<td className='light_background'>No</td>
									<td className='light_background'>No</td>
									<td className='light_background'>Yes</td>
								</tr>
								<tr>
									<td className='main_td'>
										Staking APR Bonus
									</td>
									<td className='dark_background'>12%</td>
									<td className='dark_background'>26%</td>
									<td className='dark_background'>75%</td>
									<td className='dark_background'>100%</td>
								</tr>
								<tr>
									<td className='main_td'>NFT Airdrop</td>
									<td className='light_background'>No</td>
									<td className='light_background'>No</td>
									<td className='light_background'>
										Basic NFT
									</td>
									<td className='light_background'>
										Exclusive NFT
									</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row> */}
			</Container>
		</div>
	);
}

export default Tier;
