import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Fade, Zoom } from 'react-reveal';
import Aj from '../assets/AJ.png';
import Nik from '../assets/Nik.png';
import Tuhin from '../assets/Tuhin.png';
import Darsh from '../assets/Darsh.png';
function MyTeam() {
	return (
		<myteam>
			<Container fluid>
				<div className='myteamtop'>
					<Row>
						<Zoom>
							<p className='background_text'>TEAM</p>
						</Zoom>
						<Col className='metaverse_sub_heading'>
							<p>
								<span className='sub_heading'>MEET </span>{' '}
								THE TEAM
							</p>
						</Col>
					</Row>
					<Row className='myteamsec no-gutters p-md-4 d-none d-md-flex'>
						<Col >
							<img src={Aj} className='myteam'></img>
							<p>AJ</p>
							<p>Head of Tech and Game Development</p>
						</Col>
						<Col >
							<img src={Nik} className='myteam'></img>
							<p>NIK</p>
							<p>Head of product and Blockchain Team</p>
						</Col>
						<Col >
							<img src={Tuhin} className='myteam'></img>
							<p>TUHIN</p>
							<p>Head of Growth and community</p>
						</Col>
						<Col>
							<img src={Darsh} className='myteam'></img>
							<p>DARSH</p>
							<p>Head of Marketing and Partnership</p>
						</Col>
					</Row>
					<Row className='myteamsec no-gutters d-block d-md-none mt-4'>
						<Col >
							<img src={Aj} className='myteam'></img>
							<p>AJ</p>
							<p>Head of Tech and Game Development</p>
						</Col>
						<Col >
							<img src={Nik} className='myteam'></img>
							<p>NIK</p>
							<p>Head of product and Blockchain Team</p>
						</Col>
						<Col >
							<img src={Tuhin} className='myteam'></img>
							<p>TUHIN</p>
							<p>Head of Growth and community</p>
						</Col>
						<Col>
							<img src={Darsh} className='myteam'></img>
							<p>DARSH</p>
							<p>Head of Marketing and Partnership</p>
						</Col>
					</Row>
					<Row className='yodha_section1'>
						<h2>We invite you to be a part of our vision</h2>
					</Row>
					<Row className='yodha_section1'>
						<h3>We will together, bring imagination to life. We will together, create a world that's unconstrained.</h3>
					</Row>
				</div>

			</Container>
		</myteam>
	);
}

export default MyTeam;
