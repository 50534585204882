import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Navbar,
	NavbarBrand,
	Collapse,
	Nav,
	NavItem,
	NavLink,
	Button,
	Modal,
	ModalBody,
	Input,
	Form,
	DropdownItem,
	NavbarText,
	UncontrolledDropdown,
	DropdownToggle,
	NavbarToggler,
	DropdownMenu,
	Tooltip,
} from 'reactstrap';
import logo1 from '../assets/logo1.png';
import { connectWallet } from '../utils/web3';
import AddressBar from './AddressBar';

function ThirdHeader({ showNav }) {
	const state = useSelector(state => state);
	let [collapsed, setCollapsed] = useState(false);
	let [tooltipOpen, setTooltipOpen] = useState(false);
	let [tooltipOpen2, setTooltipOpen2] = useState(false);

	const toggle1 = () => {
		setTooltipOpen(!tooltipOpen);
	};
	const toggle2 = () => {
		setTooltipOpen2(!tooltipOpen2);
	};

	const toggle = () => {
		setCollapsed(!collapsed);
	};

	const connect = () => {
		connectWallet();
	};

	return (
		<>
			{/*<div className={`${!showNav ? 'fixed-nav-bar' : 'nav_bg'}`}>
				{showNav && <AddressBar />}*/}
			<Navbar expand='lg' className='third_navbar'>
				<NavbarBrand href='/' className='ms-0  ms-lg-5'>
					<img src={logo1} className='navbar_third_logo' />
				</NavbarBrand>
			</Navbar>
		</>
	);
}

export default ThirdHeader;
