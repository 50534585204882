import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Web3 from 'web3';
const initialState = { web3:null,
  walletAddress: "",
  shortWalletAddress:"",
  balanceInWei:"",
  balance:"",
}

const web3Slice = createSlice({
  name: 'web3Slice',
  initialState,
  reducers: {
    connect(state,action) {
      state.walletAddress = action.payload;
      const shortAddress = state.walletAddress.replace(state.walletAddress.substring(5, 38), "***");
      state.shortAddress = shortAddress;
    },
    setTokenBalance(state,action){
      state.balanceInWei = action.payload.balance;
      state.balance = action.payload.balanceInEth;
    }
  },
})

export const { connect, setTokenBalance } = web3Slice.actions
export default web3Slice.reducer