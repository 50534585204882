import React from 'react';
import { Button, Col, Container, Form, Input, Row } from 'reactstrap';

function JoinUs() {
	return (
		<div className='joinus_section px-3'>
			<Container fluid>
				<Row className='d-flex align-items center'>
					<Col md='6' align='left'>
						<span>
							<strong>Join Us Now!</strong>
						</span>
						<p>
							Want to be a citizen of our Multiverse project? Then
							write to us, an amazing offer for early investors
							which you don’t want to miss out.
						</p>
					</Col>
					<Col md='6'>
						<Form>
							<Row>
								<Col xs='6'>
									<Input placeholder='First Name' />
								</Col>
								<Col xs='6'>
									<Input placeholder='Last Name' />
								</Col>
								<Col sm='12' className='mt-3'>
									<Input placeholder='Email' />
								</Col>

								<Col sm='12' className='mt-3'>
									<Input
										type='textarea'
										placeholder='Message'
										rows='4'
									/>
								</Col>
								<Col className='mt-3'>
									<Button className='px-3'>
										SEND MESSAGE
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default JoinUs;
