import React from 'react';
import nft1 from '../assets/nft1.jpg';
import nft2 from '../assets/nft2.jpg';
import nft3 from '../assets/nft3.jpg';
import { Container } from 'reactstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import nft4 from '../assets/nft4.png';
import nft5 from '../assets/nft5.jpg';

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
	},
	medium: {
		breakpoint: { max: 1024, min: 768 },
		items: 3,
	},
	tablet: {
		breakpoint: { max: 767, min: 540 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 539, min: 0 },
		items: 1,
	},
};
function PurchaseToken() {
	return (
		<div className='purchase_token_section'>
			<Container className='py-5'>
				<Carousel
					autoPlay={true}
					infinite={true}
					responsive={responsive}
				>
					<div className='purchase_card'>
						<img src={nft4} />
					</div>
					<div className='purchase_card'>
						<img src={nft5} />
					</div>
					<div className='purchase_card'>
						<img src={nft4} />
					</div>
					<div className='purchase_card'>
						<img src={nft5} />
					</div>

					<div className='purchase_card'>
						<img src={nft4} />
					</div>
					<div className='purchase_card'>
						<img src={nft4} />
					</div>
					<div className='purchase_card'>
						<img src={nft5} />
					</div>
				</Carousel>
			</Container>
		</div>
	);
}

export default PurchaseToken;
